import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrdersWithPayment } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';
import {MatDialog} from '@angular/material/dialog';
import {CustomTipDialogComponent} from './custom-tip-dialog/custom-tip-dialog.component';

@Component({
  selector: 'app-order-summary-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;
  @Input() orderHash:string;

  tipPercent: number | 'custom' = 0;
  customTipAmount: number | null = null;
  @Output() refresh = new EventEmitter<any>();

  tips = [
    {"name": "18%", "value": 18},
    {"name": "20%", "value": 20},
    {"name": "25%", "value": 25},
    {"name": "Custom", "value": 'custom'}
  ]

  customTipHidden = true;
  constructor(
    private orderService:OrderService,
    private dialog: MatDialog
  ) { }

  toggleCustomTip(){
    this.customTipHidden = !this.customTipHidden
  }

  calculateTip(tip: any) {
    if (tip.value === 'custom') {
      this.openCustomTip();
      return;
    }

    this.orderWithPayment.updateTip(tip.value);
    this.orderService.updateTip(this.orderHash, tip.value).subscribe(() => {
      this.refresh.emit(true);
    });
  }
  

  calculateCustomTip(tip: number) {
    this.orderService.updateCustomTip(this.orderHash, tip).subscribe(() => {
      this.customTipAmount = tip;
      this.orderWithPayment.updateTipCustom(+tip);
  
      const formattedTip = `$${(+tip).toFixed(2)}`;
  
      const storeTipOptions = this.orderWithPayment.getTipPercentOptions();
      this.tips = storeTipOptions.map(t => ({
        name: `${t.percent}%`,
        value: t.percent
      }));
  
      this.tips.push({ name: `Custom (${formattedTip})`, value: 'custom' });
  
      console.log('Updated tips array (after custom tip applied):', this.tips); // ✅ Add here
  
      this.tipPercent = 'custom';
      this.refresh.emit(true);
    });
  }

  ngOnInit() {
    const tipPercent = this.orderWithPayment.getTipPercent();
    const storeTipOptions = this.orderWithPayment.getTipPercentOptions();
  
    if (storeTipOptions.length > 0) {
      this.tips = storeTipOptions.map(t => ({
        name: `${t.percent}%`,
        value: t.percent
      }));
  
      const customTip = (this.orderWithPayment as any).custom_tip_amount;
      if (customTip && customTip > 0) {
        this.customTipAmount = customTip;
        const formattedCustom = `$${(+customTip).toFixed(2)}`;
        this.tips.push({ name: `Custom (${formattedCustom})`, value: 'custom' });
  
        this.tipPercent = 'custom';
        return; // ✅ EXIT EARLY — don’t reassign tipPercent below
      } else {
        this.tips.push({ name: 'Custom', value: 'custom' });
      }
    }
  
    // ✅ Only needed if no custom tip
    const isPreset = this.tips.some(t => t.value === tipPercent);
    this.tipPercent = isPreset ? tipPercent : 'custom';
  
    console.log('Tips array after ngOnInit:', this.tips);
  }
  

  openCustomTip() {
    const dialogRef = this.dialog.open(CustomTipDialogComponent, {
      width: '80vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result !== null && result !== '') {
        this.calculateCustomTip(result);
      }
    });
  }
  

}
